import React from 'react'
import "./Loading.css";

export const Loading = () => {
  return (
    <div className='loading'></div>
  )
}


